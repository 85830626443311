export default {
  inject: ['modal'],
  props: {
    product: {
      type: Object,
      required: true,
    },

    isModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    setCartPending (payload) {
      const cartIdCookie = this.$cookies.get('cart-id') || ''
      const cartIdStore = this.$cart.cartId() || ''

      this.$cart.setCartLoaded(!payload)

      if (cartIdCookie.localeCompare(cartIdStore)) {
        this.$cart.refreshCart()
      }
    },

    async handleAddedToCart (product, payload) {
      await this.$cart.addedToCart(payload)

      try {
        const ProductAddedToCartModal = await import('~/components/product/Modal/ProductAddedToCartModal.vue')
        const [{ cart: { relatedProducts } }] = Object.values(payload)
        const modalInstance = await this.modal()

        modalInstance.openModal(ProductAddedToCartModal.default, {
          product,
          relatedProducts,
          gtmLabel: this.$t('Others also bought'),
        }, { width: [{ mobile: '100%', desktop: '964px' }] })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },

    async handleAddToCart (product, payload) {
      await this.$cart.addedToCart(payload)

      this.$emit('added-to-cart')
      this.$emit('hide')
    },
  },
}
