export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  computed: {
    shouldOpenModal () {
      return [
        'LensesProduct',
        'MedicalCheckProduct',
        'MedicalPackageProduct',
        'OneTimeServiceProduct',
        'PackageOfServicesProduct',

        // 'PhysicalProduct',
        // 'PrepaidCardProduct',
        'SimpleProduct',
        'SimpleService',
      ].includes(this.product._typename)
    },

    canAddToCartOnModal () {
      return [
        'LensesProduct',
        'MedicalCheckProduct',
        'MedicalPackageProduct',
        'OneTimeServiceProduct',
        'PackageOfServicesProduct',
        'PhysicalProduct',
        'PrepaidCardProduct',
        'SimpleProduct',
        'SimpleService',
      ].includes(this.product._typename)
    },
  },

  methods: {
    addedToCartFromModal () {
      this.$root.$emit('global-added-to-cart-from-modal')
      this.$root.$emit('global-open-mini-cart')
    },
  },
}
