
export default {
  name: 'BaseChips',
  props: {
    label: {
      type: String,
      default: 'default',
    },

    vendor: {
      type: Boolean,
      default: false,
    },
  },
}
