
import getProductDataQuery from '~/graphql/products/queries/getProductData.graphql'
import modalAddToCartMixin from '~/mixins/modalAddToCart'
import productAddedToCartMixin from '~/mixins/productAddedToCart'
import camelCaseKeysToUnderscore from '~/utils/stringTransform/camelCaseKeysToUnderscore'

export default {
  name: 'ProductTileAddToCartButton',
  mixins: [
    modalAddToCartMixin,
    productAddedToCartMixin,
  ],

  inject: ['modal'],

  data: () => ({
    isPending: false,
    isOpen: false,
    dataProduct: null,
    isAdded: false,
    lastButtonActionOpenedModal: false,
  }),

  computed: {
    cartItems () {
      const { optionId, optionTypeId } = this.dataProduct.options[0].value[0]

      return [
        {
          data: {
            sku: this.dataProduct.sku,
            quantity: 1,
          },

          customizableOptions: [
            {
              id: optionId,
              valueString: String(optionTypeId),
            },
          ],
        },
      ]
    },
  },

  watch: {
    isAdded () {
      setTimeout(() => {
        this.isAdded = false
      }, 2000)
    },
  },

  mounted () {
    this.$root.$on('global-added-to-cart-from-modal', this.scrollToCart)
  },

  beforeDestroy () {
    this.$root.$off('global-added-to-cart-from-modal', this.scrollToCart)
  },

  methods: {
    async onAddToCart () {
      this.isPending = true

      await this.$graphql({
        requestPayload: {
          query: getProductDataQuery,
          variables: {
            filter: {
              url_key: {
                eq: this.product.urlKey,
              },
            },
            sort: {
              name: 'ASC',
            },
          },
          useGETForQueries: true,
          context: {
            fetchOptions: {
              method: 'GET',
            },
          },
        },
        successHandler: async ({ data: { products: { items: [product] } } }) => {
          this.dataProduct = product

          if (this.shouldOpenModal) {
            this.showModal()
            this.lastButtonActionOpenedModal = true
          } else {
            await this.addItemToCart()
          }
        },
      })

      this.isPending = false
    },

    showModal () {
      this.isOpen = true

      this.$nextTick(async () => {
        try {
          const ProductModalAddToCart = await import('~/components/product/Modal/ProductModalAddToCart.vue')
          const modalInstance = await this.modal()

          modalInstance.openModal(ProductModalAddToCart.default, { product: this.dataProduct })
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
        }
      })
    },

    scrollToCart () {
      if (this.lastButtonActionOpenedModal) {
        this.isAdded = true
        this.lastButtonActionOpenedModal = false
      }
    },

    async addItemToCart () {
      this.isPending = true

      await this.$graphql({
        requestPayload: {
          mutation: this.$cart.addToCartMutation(this.product._typename),
          variables: camelCaseKeysToUnderscore(this.$cart.addToCartInput(this.cartItems)()),
        },
        successHandler: async ({ data }) => {
          await this.handleAddToCart(this.dataProduct, data)

          this.isAdded = true
          this.$root.$emit('global-open-mini-cart')
        },
      })
      this.isPending = false
    },
  },
}
